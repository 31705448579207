import { InjectionKey, Ref, Component } from 'vue';
import { DataSourceEntries } from '@distancify/drapejs-storyblok';

interface Settings {
  footerLinks: any
  footerLinks2: any
  logoLight: any
  logoDark: any
  socialMedia: any
  contactLink: any
  privacyPolicyLink: any
  offices: any
}

export const textsKey: InjectionKey<Ref<DataSourceEntries>> = Symbol('texts');
export const settingsKey: InjectionKey<Ref<Settings>> = Symbol('settingsKey');
