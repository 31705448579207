
import { defineComponent } from 'vue';
import { ref, computed, watch, onMounted } from 'vue';
import useContext from '@/composables/useContext';
import { imageResize, imageFit } from '@distancify/drapejs-storyblok';

export default defineComponent({
  name: 'SiteHeader',
  setup() {

    const isNavOpen = ref(false);

    const toggleNav = () => {
      isNavOpen.value = !isNavOpen.value;
    }

    const pop = (idx: number) => {
      return `animation-delay: calc(var(--initial-delay) + (var(--step-delay) * ${idx}))`
    }

    const isScrolled = ref(false);

    onMounted(() => {
      document.addEventListener('scroll', (e) => {
        isScrolled.value = document.documentElement.scrollTop > 0;
      });
    });

    const { page, settings, route, resolveLink } = useContext();

    const logoLight = computed(() => settings?.value?.logoLight?.id ? imageResize(settings.value.logoLight, { height: 36, width: 0 }) : null);
    const logoDark = computed(() => settings?.value?.logoDark?.id ? imageResize(settings.value.logoDark, { height: 36, width: 0 }) : null);

    watch(() => route?.pathname, () => {
      isNavOpen.value = false;
    })

    const headerClasses = computed(() => {
      const classes: string[] = [];
      if (isScrolled.value) {
        classes.push('header--scrolled');
      }

      if (page?.value.headerStyle) {
        classes.push(`header--${page.value.headerStyle}`);
      }
      return classes;
    });

    const logoClasses = computed(() => {
      const classes: string[] = [];
      if (isScrolled.value) {
        classes.push('header__logo-small--dark');
        classes.push('header__logo-medium--dark');
        classes.push('header__logo-large--dark');
        return classes;
      }

      classes.push(`header__logo-small--${page.value?.logoStyleSmall || 'dark'}`);
      classes.push(`header__logo-medium--${page.value?.logoStyleMedium || 'dark'}`);
      classes.push(`header__logo-large--${page.value?.logoStyleLarge || 'dark'}`);

      return classes;
    });

    const openMenuClasses = computed(() => {
      const classes: any[] = [];
      if (isScrolled.value) {
        classes.push('header__open-menu-small--dark');
        classes.push('header__open-menu-medium--dark');
        classes.push('header__open-menu-large--dark');
        return classes;
      }

      classes.push(`header__open-menu-small--${page.value?.menuStyleSmall || 'dark'}`);
      classes.push(`header__open-menu-medium--${page.value?.menuStyleMedium || 'dark'}`);
      classes.push(`header__open-menu-large--${page.value?.menuStyleLarge || 'dark'}`);

      return classes;
    });

    return {
      isNavOpen,
      toggleNav,
      pop,
      isScrolled,
      resolveLink,
      page,
      settings,
      route,
      logoLight,
      logoDark,
      headerClasses,
      logoClasses,
      openMenuClasses,
      imageFit,
    }
  },
});

