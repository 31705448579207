import { computed, inject, Ref, ref, InjectionKey } from 'vue';
import { DataSourceEntries } from '@distancify/drapejs-storyblok';

const tokenRegex = /\{{(.+?)\}}/gi;

export const textsKey: InjectionKey<Ref<DataSourceEntries>> = Symbol('texts');

function replace(textToFormat: string, websiteTexts: Ref<{}>, tokens?: { [key:string]: String | Number }) {
  if (!tokens) {
    tokens = {};
  }

  return textToFormat.replace(tokenRegex, (_, p1: String) => {
    
    if (tokens) {
      let token = tokens[p1.toLowerCase()];
      if (typeof token !== 'undefined') {
        return token.toString();
      }
    }
    
    let token = websiteTexts.value?.[p1.toLowerCase()];
    if (typeof token !== 'undefined') {
      return token.toString();
    }
    
    return `{${p1.toLowerCase()}}`;
  });
}

export default function(texts?: Ref<DataSourceEntries>) {
  if (!texts)
  {
    texts = inject(textsKey, ref({}));
  }

  return {
    text: (key: string, tokens?: { [key:string]: String | Number }) => computed<string>(() => {
      return replace(texts?.value?.[key] || key, texts || ref({}), tokens);
    }),
  }
}