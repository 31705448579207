<script>
import { defineComponent } from 'vue';
import Layout from './components/Layout';

export default defineComponent({
  name: 'App',
  components: {
    Layout
  },
});
</script>

<template>
  <router-view>
    <layout/>
  </router-view>
</template>
