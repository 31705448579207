import { inject, ref } from 'vue';
import { pageKey, routeKey } from '@drapejs/core';
import {
  resolveLinkKey
} from '@distancify/drapejs-storyblok';
import { settingsKey, textsKey } from '@/keys';

export default function () {
  return {
    page: inject(pageKey, ref()),
    settings: inject(settingsKey, ref()),
    route: inject(routeKey, <any>{}),
    texts: inject(textsKey, <any>{}),
    resolveLink: inject(resolveLinkKey, () => <any>null),
  };
}
