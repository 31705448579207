import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_router_page = _resolveComponent("router-page")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TrackingConsentV2 = _resolveComponent("TrackingConsentV2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SiteHeader),
    _createElementVNode("div", {
      class: _normalizeClass(['layout__content', $setup.themeClass])
    }, [
      _createVNode(_component_router_page)
    ], 2),
    _createVNode(_component_SiteFooter),
    _createVNode(_component_TrackingConsentV2, {
      privacyPolicyUrl: $setup.resolveLink(() => $setup.settings?.privacyPolicyLink)?.url,
      grantAllLabel: $setup.text('consent__grant-all-label').value,
      saveLabel: $setup.text('consent__save-label').value,
      essentialsLabel: $setup.text('consent__essentials-label').value,
      consents: [
      {
        type: 'analytics_storage',
        label: $setup.text('consent__analytics-label'),
      },
      {
        type: 'ad_storage',
        label: $setup.text('consent__ad-label'),
      },
    ]
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString($setup.text('consent__message').value), 1),
        _createElementVNode("p", null, [
          _createVNode(_component_router_link, {
            to: $setup.resolveLink(() => $setup.settings?.privacyPolicyLink)?.url
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.text('consent__read-more-label').value), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      _: 1
    }, 8, ["privacyPolicyUrl", "grantAllLabel", "saveLabel", "essentialsLabel", "consents"])
  ], 64))
}