import { defineCommand, defineReceiver } from '@drapejs/invoker'
import fetch from 'cross-fetch'

export const fetchStories = defineCommand<{
  starts_with: string,
  is_startpage?: boolean,
}>('fetchStories');

export const loadBlog = defineCommand<{}>('loadBlog');

export const fetchStoriesReceiver = defineReceiver(fetchStories, async function (command, data) {
  const config = await this.cache.getItem('__storyblok');
  const fetchDraft = config.fetchDraft;

  let query = '';
  const queryItems: string[] = [];
  
  if (command.starts_with) {
    queryItems.push(`starts_with=${command.starts_with}`);
  }
  if (command.is_startpage !== undefined) {
    queryItems.push(`is_startpage=${command.is_startpage ? '1' : '0'}`);
  }

  if (queryItems.length > 0) {
    query = `&${queryItems.join('&')}`;
  }

  let cv = '';
  if (!fetchDraft) {
    cv = '&cv=' + Math.floor(Date.now() / 60000);
  }

  var response = await fetch(`//api.storyblok.com/v2/cdn/stories?token=${config.token}${cv}${query}&version=${fetchDraft ? 'draft' : 'published'}`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });

  if (response.ok) {
    const body = (await response.json());
    const stories: any[] = [];

    body.stories.forEach((story: any) => {
      data = story.content;
      data.links = body.links || [];

      data.alternates = [];
      if (story.translated_slugs?.length > 0) {
        const slug = story.translated_slugs.find((r: any) => r.lang === story.lang);
        data.name = slug?.name || story.name;

        if (story.lang === 'default') {
          data.alternates = story.translated_slugs;
        } else {
          data.alternates = [{
            lang: 'default',
            name: story.name,
            path: story.default_full_slug
          }].concat(story.translated_slugs);
        }
      } else {
        data.name = story.name;
      }

      if (story.alternates?.length > 0) {
        data.alternates = data.alternates.concat(story.alternates.filter((s: any) => {
          return fetchDraft || s.published;
        }).map((s: any) => {
          return {
            path: s.full_slug,
            name: s.name
          }
        }))
      }

      data.type = data.component;
      data._id = story.id;
      data.http_status = 200;
      data.first_published_at = story.first_published_at;
      data.full_slug = story.full_slug;

      this.cache.setItem('/' + story.full_slug, data);

      stories.push(story.content);
    });

    return stories;
  }
});

export const loadBlogReceiver = defineReceiver(loadBlog, async function (command, data) {
  const articles = (await this.invoke(fetchStories, {
    starts_with: 'blog/',
    is_startpage: false,
  }));

  this.cache.setItem('__blog', articles);
});