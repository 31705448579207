import { createSSRApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import drapejs, { Options } from '@drapejs/core';
import Storyblok, { StoryblokOptions } from '@distancify/drapejs-storyblok';
import StandardComponents from '@distancify/storyblok-standard-components';

import consola from 'consola';

import './style/index.css'

consola.level = 5;

export default function(workerFactory?: () => Worker) {
  const app = createSSRApp(App)
    .use(drapejs, {
      pageComponents: {
        Settings: defineAsyncComponent(() => import('./pages/Settings.vue')),
        ContentPage: defineAsyncComponent(() => import('./pages/ContentPage.vue')),
        NotFound: defineAsyncComponent(() => import('./pages/NotFound.vue')),
        BlogIndex: defineAsyncComponent(() => import('./pages/BlogIndex.vue')),
        BlogPost: defineAsyncComponent(() => import('./pages/BlogPost.vue')),
      },
      workerFactory,
    })
    .use(Storyblok, {
      token: "7C5TE0WhBj3krrCKhLrzQwtt",
      fetchDraft: process.env.NODE_ENV == 'development',
      blockComponents: {
        ...StandardComponents.blocks,
        Text: defineAsyncComponent(() => import('./blocks/Text.vue')),
        Margin: defineAsyncComponent(() => import('./blocks/Margin.vue')),
        VideoPlayer: defineAsyncComponent(() => import('./blocks/VideoPlayer.vue')),
        Button: defineAsyncComponent(() => import('./blocks/Button.vue')),
        BubbleButton: defineAsyncComponent(() => import('./blocks/BubbleButton.vue')),
      }
    });
  
  return app;
}
