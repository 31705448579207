
import { defineComponent } from 'vue';
import { computed } from 'vue';
import useContext from '@/composables/useContext';
import { imageResize, imageFit } from '@distancify/drapejs-storyblok';
import useMarkdown from '@/composables/useMarkdown';

export default defineComponent({
  name: 'SiteFooter',
  setup() {
    
    const { settings, texts, resolveLink } = useContext();
    const logoLight = computed(() => settings?.value?.logoLight?.id ? imageResize(settings.value.logoLight, { height: 36, width: 0 }) : null);


    return {
      ...useMarkdown(),
      logoLight,
      settings,
      texts,
      resolveLink,
      imageFit,
    }
  },
});
