
import {
  onServerPrefetch,
  ref,
  inject,
  provide,
  computed,
  onMounted,
  watchEffect,
} from "vue";

import {
  fetchPage,
  fetchPageBuilder,
  getServerItemKey,
  routeKey,
  pageKey,
  subscribeKey,
  invokeKey,
  getItemKey,
} from "@drapejs/core";

import {
  useDataSources,
} from '@distancify/drapejs-storyblok';

import { loadBlog } from '@/connectors/storyblok';

import SiteHeader from './SiteHeader.vue'
import SiteFooter from './SiteFooter.vue'

import { settingsKey } from '../keys';

import useTexts, { textsKey } from '@/composables/useTexts';
import useContext from '@/composables/useContext';
import { usePageView, TrackingConsentV2 } from '@distancify-storefront/tracking-gtm';

let openModals: any[] = [];

function updateScroll() {
  if (openModals.length) {
    document.body.style.marginRight = (
      window.innerWidth - document.body.offsetWidth
    ).toString();
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.marginRight = "";
    document.body.style.overflow = "";
  }
}

export default {
  name: 'Layout',
  components: {
    SiteHeader,
    SiteFooter,
    TrackingConsentV2,
  },
  setup() {
    const { page, resolveLink } = useContext();
    const getServerItem = inject(getServerItemKey, () => { });
    const getItem = inject(getItemKey, () => { return null as any });
    const subscribe = inject(subscribeKey, () => { return null as any });
    const invoke = inject(invokeKey, () => { return null as any });
    const route = inject(routeKey);
    const settingsFetchPage = fetchPageBuilder(route?.protocol || '', route?.host || '', `/settings`);
    const { fetchDataSource, getDataSource, getServerDataSource, subscribeDataSource } = useDataSources();

    usePageView();

    const settings = ref(getServerItem(settingsFetchPage.cacheKey));
    provide(settingsKey, settings);

    const texts = ref(getServerDataSource('texts'));
    provide(textsKey, texts);

    const { text } = useTexts(texts);

    onServerPrefetch(async () => {
      settings.value = await invoke(fetchPage, settingsFetchPage);
      texts.value = await fetchDataSource('texts');

      await invoke(loadBlog, {});
    });
    
    subscribe(settingsFetchPage.cacheKey, (val) => {
      settings.value = val;
    });

    if (!settings.value) {
      onMounted(async () => {
        settings.value = await getItem(settingsFetchPage.cacheKey);
        settings.value = await invoke(fetchPage, settingsFetchPage);
      });
    }

    subscribeDataSource('texts', (val) => {
      texts.value = val;
    });
    if (!texts.value) {
      onMounted(async () => {
        texts.value = await getDataSource('texts');
        texts.value = await fetchDataSource('texts');
      });
    }

    onMounted(async () => {
      await invoke(loadBlog, {});
    });

    const themeClass = computed(() => {
      if (page.value.theme) {
        return `theme--${page.value.theme}`;
      }
    })

    return {
      texts,
      settings,
      resolveLink,
      text,
      themeClass,
    };
  },
  provide() {
    return {
      registerModal: (key: string) => {
        openModals = openModals.filter((k) => k !== key);
        openModals.push(key);
        updateScroll();
      },
      unregisterModal: (key: string) => {
        openModals = openModals.filter((k) => k !== key);
        updateScroll();
      },
    };
  },
};

